import "./roadmap.css";
import imageLeft from "../assets/hands1.png";
import imageRight from "../assets/roadmap content right.png";

import { Grid, Typography } from "@mui/material";
import Box from "@mui/system/Box";
import Headings from "../utils/Headings";

export default function RoadMap() {
  return (
    <Box className="main-container">
      <Box sx={{ px: "4em" }}>
        <div className="roadmap-wrapper" id={"road-map"}>
          <Headings text="Roadmap" sx={{ marginBottom: 4 }} />
          {/* <Typography  variant="h2"
            sx={{
              fontFamily: "Cherry Bomb",
              textAlign: "center",
            }} >Roadmap</Typography> */}
        </div>

        <Grid
          container
          spacing={2}
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Grid item xs={12} sm={12} md={4}>
            <Box
              component="img"
              alt="image"
              src={imageLeft}
              sx={{
                width: { xs: "100%", md: "auto" },
                height: { xs: "100%", md: "auto" },
                // width: "100%",
                // height: "100%",
                objectFit: "cover",
                // '@media (min-width: 390px)': {
                //   width: "12.5em",

                // },
                // '@media (min-width: 1366px)': {
                //   width: "18.5em",
                // },
                // '@media (min-width: 1440px)': {
                //   width: "22.5em",
                // },
                // '@media (min-width: 1920px)': {
                //   width: "24.5em",
                // },
              }}
            />
          </Grid>
          <Grid
            sx={{
              display: "flex",
              justifyContent: "center",
              my: { xs: 2, md: 0 },
            }}
            item
            xs={12}
            sm={12}
            md={4}
          >
            <Box
              sx={{
                // width: "15em",

                backgroundColor: "#00000080", // Background color with opacity
                border: "2px solid #FFFEF8E5", // White border all around the box
                boxShadow: "0px 0px 50px 20px #FFFEF82E", // Box shadow with transparency
                padding: "20px 21px 20px 20px", // Padding inside the box
                borderRadius: "0px 30px 0px 30px", // Rounded corners
                lineHeight: "36.74px", // Line height for text
                display: "flex", // Flexbox to center items vertically
                flexDirection: "column", // Column direction to stack Typography elements
                alignItems: "left", // Center align items horizontally
                textAlign: "left", // Center align text
                fontFamily: "Blinker", // Font family
                fontSize: "22px", // Font size
                fontWeight: 400, // Font weight
                marginBottom: 4,
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Blinker",
                  fontSize: "1em",
                  fontWeight: 400,
                  my:1
                }}
              >
                1. Meme Creation
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Blinker",
                  fontSize: "1em",
                  fontWeight: 400,
                  my:1
                }}
              >
                2. Launch,build community & marketing
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Blinker",
                  fontSize: "1em",
                  fontWeight: 400,
                  my:1
                }}
              >
              3. Meme Takeover
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Blinker",
                  fontSize: "1em",
                  fontWeight: 400,
                  my:1
                }}
              >
                Phase 2: Vibe and HODL
              </Typography>
              
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            sx={{
              display: "flex",
              justifyContent: "flex-end", // Aligns the content to the end horizontally
              alignItems: "flex-end", // Aligns the content to the end vertically
            }}
          >
            {/* <img
            alt="image"
            src={imageRight}
            style={{ width: "22em",  }}
          /> */}

            <Box
              component="img"
              alt="image"
              src={imageRight}
              sx={{
                width: { xs: "100%", md: "auto" },
                height: { xs: "100%", md: "auto" },
                // width: "100%",
                // height: "100%",
                objectFit: "cover",
                // '@media (min-width: 390px)': {
                //   width: "12.5em",

                // },
                // '@media (min-width: 1366px)': {
                //   width: "18.5em",
                // },
                // '@media (min-width: 1440px)': {
                //   width: "22.5em",
                // },
                // '@media (min-width: 1920px)': {
                //   width: "24.5em",
                // },
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
