import { Box, Container, Typography, } from "@mui/material";

import ResponsiveAppBar from "../components/Appbar";

import "./home.css";
import FooterSection from "../components/tokenNomics/FooterSection";
import AboutSection from "../components/about/AboutSection";
import SocialIcons from "../utils/SocialIcons";


export default function AboutPage() {
  return (
    <div className="HomePage-Parent" style={{
        minHeight:"99vh"
    }} >
   
   <ResponsiveAppBar />
     
        
      <AboutSection/>




  <Box sx={{
    // paddingTop:{xs:"auto", md:"10vh" , xl:"30vh"}

    // position: "fixed",
    // bottom: 0,
    // left: 0,
    // width: "100%", 
    // display: "flex",
    // alignItems: "center",
    // justifyContent: "center",
    // textAlign: "center",
  }}>
    <SocialIcons />
      < FooterSection/>
      </Box>
      
 
      
    
    </div>
  );
}
