import React from 'react';
import logo from './logo.svg';
import './App.css';
import Home from './pages/Home';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import TokenNomicsPage from './pages/TokenNomics';
import AboutPage from './pages/about';

function App() {
  return (
<>

    <Router>
      <Routes>
          <Route path="/" element={<Home/>} />
         <Route  path="/token-nomics" element={<TokenNomicsPage/>} />
         <Route  path="/about" element={<AboutPage/>} />
      </Routes>
    </Router>
    </>
    
    // <Routes>
    //     <Route path="/" element={<Home/>} />
    //     <Route  path="/token-nomics" element={<TokenNomicsPage/>} />
    // </Routes>

    // <div className="App">
    //  {/* <Home/> */}
    //  <TokenNomicsPage/>
    // </div>
  );
}

export default App;
