import React, { ReactNode } from 'react';
import { Box } from '@mui/material';

interface DivContainerProps {
  children: ReactNode;
}

const DivContainer: React.FC<DivContainerProps> = ({ children }) => {
  return (
    <Box
      sx={{
        backgroundColor: "#00000080",
        border: "2px solid #FFFEF8E5",
        boxShadow: "0px 0px 50px 20px #FFFEF82E",
        padding: "15px 20px",
        borderRadius: "0px 30px 0px 30px",
        lineHeight: "36.74px",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        textAlign: "left",
      }}
    >
      {children}
    </Box>
  );
}

export default DivContainer;
