import { Box, Grid } from "@mui/material";


import telegramLogo from "../assets/Telegram.png"
import twitterLogo from "../assets/TwitterX.png"
import instagramLogo from "../assets/Instagram.png"
import discordLogo from "../assets/Discord New.png"




export default function SocialIcons() {
    return (
      <Box>
        <Grid
          container
          spacing={2} // Added spacing between grid items
          sx={{
            display: "flex",
            flexDirection: "row", // Changed to row to align icons horizontally
            alignItems: "center",
            justifyContent: "center",
            marginTop: '10px'
          }}
        >
          {/* <Grid item>
            <img alt="Discord Icon" src={discordLogo} />
          </Grid> */}
          <Grid item>
            <a href="https://tr.ee/BSUrkGzT1t" target="_blank" rel="noreferrer"><img alt="Telegram Icon" src={telegramLogo} /></a>
            
          </Grid>
          <Grid item>
          <a href="https://tr.ee/vVxqN1aOou" target="_blank" rel="noreferrer"><img alt="Twitter Icon" src={twitterLogo} /></a>
          </Grid>
          {/* <Grid item>
            <img alt="Instagram Icon" src={instagramLogo} />
          </Grid> */}
        </Grid>
      </Box>
    );
  }
  