import { Box, Grid } from "@mui/material";
import Headings from "../../utils/Headings";
import Content from "../../utils/Content";

export default function AboutSection() {
  return (
    <Box>
      <Grid container className="about-container">
        <Grid item xs={12} sm={12} md={12}>
          <Box sx={{ textAlign: "center" }}>
            <Headings text="The Backstory" variant="h3" />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Content
            text={
              <>
                {"Where the Moopetz breed comes from…"}
                <br />
                {
                  "Professor L. Bull (aka Fatalis) and Professor K. Weinstein (aka Demens), a couple of mad scientists kicked out of Area 51 for their wild experiments, made off with some potions and animal DNA. After evading Area 51 officials for a year, they set up a secret lab in the outback and started crossbreeding their wild killer bull, with frogs and eventually humans, creating weird hybrids they called Moopetz."
                }
                <br />
                <br />
                {
                  "Their experiments were a disaster, burning down the lab three times while in the process of breeding the Moopetz."
                }
                <br />
                <br />
                {
                  "Their crazy creations often escaped, turning their secret outback farm lab into a hilarious mess. Despite all the chaos, Professor Bull and Professor Weinstein keep pushing the limits, always experimenting, wildly..."
                }
              </>
            }
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <Headings text="About" variant="h3" />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Content
            text={
              "The team at Moopetz greatly values the community and wants to pay homage to it by putting something fun into the ecosphere for everyone to enjoy. The community is at the heart of this project and we understand that trust is at the center of what makes this great. We have agreed to keep a keen eye on the response we receive to this project, so we can keep developing it until it’s something we can all be proud of. We are invested in creating a trusted community for us and our users. Your continued support for the project will allow us to keep on developing and building. We thank you and we are proud to have you with us; we look forward to mooooon-ing! -The ‘Moopetz’ Team."
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
}
