import { Box, Grid, Link, Typography } from "@mui/material";

import moopetLogo from "../assets/Moopets logo 11.png";
import telegramLogo from "../assets/Telegram.png";
import twitterLoo from "../assets/TwitterX.png";
import SocialIcons from "../../utils/SocialIcons";
// import cage from "../assets/cage 1.svg"

export default function FooterSection() {
  return (
    <Box className="main-container" >
      <Box sx={{marginTop:"4.5em",paddingBottom:"2em"}}>

      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        sx={{
          marginTop:"2em",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >





<Typography
          variant="body1"
          sx={{
            width: "90vw",
            fontFamily: "Blinker",
            fontSize: "1em",
            fontWeight: 600,
            lineHeight: "31px",
            textAlign: "center",
            mb: 2, // Add some margin bottom for spacing

            '@media (min-width: 390px)': {
              fontSize: "1em",
              lineHeight: "23px",
              width: "90vw",
            },
            '@media (min-width: 1366px)': {
              fontSize: "1em",
              lineHeight: "26px",
              width: "90vw",
            },
            '@media (min-width: 1440px)': {
              fontSize: "1.2em",
              lineHeight: "27px",
              width: "90vw",
            },
            '@media (min-width: 1920px)': {
              fontSize: "1.5em",
              lineHeight: "31px",
              width: "90vw",
            },
          }}
        >
        $MPETZ is a meme coin with no intrinsic value of financial gain. There is no formal team or set roadmap. $MPETZ coin is completely useless and for entertainment purposes.
        </Typography>

        <Typography
          sx={{
            width: "30vw",
            fontFamily: "Jost",
            fontSize: "1em",
            fontWeight: 500,
            lineHeight: "28.9px",
            textAlign: "center",
            mt: 1, // Add some margin bottom for spacing
            '@media (min-width: 250px)': {
              fontSize: "0.6em",
              lineHeight: "20.9px",
              width: "90vw",
            },
            '@media (min-width: 370px)': {
              fontSize: "0.7em",
              lineHeight: "28.9px",
              width: "70vw",
            },
            '@media (min-width: 1366px)': {
              fontSize: "1em",
              lineHeight: "28.9px",
              width: "30vw",
            },
            '@media (min-width: 1440px)': {
              fontSize: "1em",
              lineHeight: "28.9px",
              width: "30vw",
            },
            '@media (min-width: 1920px)': {
              fontSize: "1em",
              lineHeight: "28.9px",
              width: "30vw",
            },
          }}
        >
          © 2024 by mootpetz. All right reserved!
          <br/>
          <Link href="mailto:contact@moopetz.com" sx={{ color: '#5C1FAB', textDecoration: 'none' }}>
        contact@moopetz.com
      </Link>
        </Typography>
      </Grid>
    </Box>
    </Box>
  );
}
