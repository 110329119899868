import { Box, Container, Typography, } from "@mui/material";
import About from "../components/About";
import ResponsiveAppBar from "../components/Appbar";
import Buy from "../components/Buy";
import Footer from "../components/Footer";
import MoopetzSection from "../components/MoopetzSection";
import Navbar from "../components/Navbar";
import RoadMap from "../components/RoadMap";
import TokenNomics from "../components/tokenNomics/TokenNomics";

import "./home.css";
import FooterSection from "../components/tokenNomics/FooterSection";


export default function TokenNomicsPage() {
  return (
    <div className="HomePage-Parent" >
      {/* <Navbar/> */}
      <ResponsiveAppBar />
      <Box sx={{height:"100%"}}>
      <TokenNomics />
      < FooterSection/>
      </Box>
    
    </div>
  );
}
