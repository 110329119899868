import React from "react";
import { Grid, Box, Typography } from "@mui/material";
import telegramLogo from "../assets/Telegram.png";
import twitterLogo from "../assets/TwitterX.png";
import ufo from "../assets/ufo-moo.png";
import SocialIcons from "../utils/SocialIcons";
import animatedUfo from "../assets/b.gif";
import moopet from "../assets/Moopet_Clean.svg";
import milkSplit from "../assets/Group 13.png";
import TokenInfoCard from "../utils/TokenInfoCard";
import Fozzie from "../assets/Fozzie.png";
import Animoo from "../assets/Animoo.png";

export default function UFO() {
  return (
    <>
      <Grid
        id={"how-to-buy-content"}
        container
        spacing={1}
        sx={{ px: { xs: 2, md: 4 } }}
      >
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            py: 0,
            my: 0,
            marginTop: 4,
            display: { xs: "block", md: "none" },
          }}
        >
          <Box
            sx={{
              py: 0,
              my: 0,
              position: "relative",
            }}
          >
            <img
              loading="lazy"
              alt=""
              src={animatedUfo}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
            <img
              loading="lazy"
              alt=""
              src={moopet}
              style={{
                position: "absolute",
                bottom: 0,
                left: "23%",
                // left: "12.8rem",
                height: "60%",
              }}
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          {/* <Box sx={{ width: { xs: "100%", md: "50%" } }}>
            <TokenInfoCard
              title={"1.	Create a wallet. "}
              content={
                <>
                  {
                    "Download phantom or your SOL wallet of choice from the app store or google play Store for free. Desktop users, download the google chrome extension by going to "
                  }
                  <a
                    style={{ color: "white" }}
                    href="https://phantom.app/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    phantom.app
                  </a>
                  {" or to the google chrome extensions store."}
                </>
              }
            />
          </Box>
          
          <Box sx={{ width: { xs: "100%", md: "50%" } }}>
          <img
    loading="lazy"
    alt=""
    src={milkSplit}
    style={{
      minWidth: "57%",
      minHeight: "50%",
      objectFit: "cover",
    }}
  />
          </Box> */}

          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TokenInfoCard
                title={"1. Create a wallet."}
                content={
                  <>
                    {
                      "Download phantom or your SOL wallet of choice from the app store or google play Store for free. Desktop users, download the google chrome extension by going to "
                    }
                    <a
                      style={{ color: "white" }}
                      href="https://phantom.app/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      phantom.app
                    </a>
                    {" or to the google chrome extensions store."}
                  </>
                }
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: { xs: "none", md: "block" },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center", // Align the image in the center of the box
                  alignItems: "center", // Center the image vertically in the box
                  height: "100%", // Ensure the box takes the full height of the grid item
                  width: "100%", // Ensure the box takes the full width of the grid item
                }}
              >
                <img
                  loading="lazy"
                  alt=""
                  src={Fozzie}
                  style={{
                    maxWidth: "50%",
                    // maxHeight: '50%',
                    objectFit: "contain", // Ensure the image fits within the box and maintains its aspect ratio
                  }}
                />
              </Box>
            </Grid>
          </Grid>

          <Box sx={{ width: { xs: "100%", md: "50%" }, marginLeft: "auto" }}>
            <TokenInfoCard
              title={"2.	Get some SOL (solana’s native token) "}
              content={
                "Have SOL in your wallet to switch to $MPETZ. If you don’t have any SOL, you can buy directly on phantom, transfer from another wallet, or buy on another exchange and send it to your phantom wallet or SOL wallet of your choice."
              }
              style={{
                marginLeft: "auto",
              }}
            />
          </Box>

          {/* <Box sx={{ width: { xs: "100%", md: "50%" } }}>
            <TokenInfoCard
              title={"3.	Go to Raydium "}
              content={
                <>
                  {"Connect to Raydium. Go to "}
                  <a
                    style={{ color: "white" }}
                    href="https://raydium.io/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    raydium.io
                  </a>
                  {
                    "in google chrome or on the browser inside your phantom app. Connect your wallet. Paste the $MPETZ token address into raydium, select $MPETZ, and confirm. When phantom prompts you for a wallet signature, sign.. ( Contract address: insert here)"
                  }
                </>
              }
            />
          </Box> */}

          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TokenInfoCard
              style={{display:'block',}}
                title={"3.	Go to Pump.fun "}
                content={
                  <>
                    {"Connect to Pump.fun by going to "}
                    <a
                      style={{ color: "white" }}
                      href="https://raydium.io/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Pump.fun
                    </a>
                    {
                      `  in chrome or on the browser inside your wallet phantom app. Connect your wallet. Search for $MPETZ or paste the $MPETZ token address (ECRNyo34xm5EWWstfcQmX
                      5MomnHjKERoSYDR8Kjwpump), select $MPETZ, and confirm the amount. When phantom prompts you for a wallet signature, sign.`
                    }
                  </>
                }
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: { xs: "none", md: "block" },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center", // Align the image in the center of the box
                  alignItems: "center", // Align the image at the bottom of the box
                  height: "100%", // Ensure the box takes the full height of the grid item
                  width: "100%", // Ensure the box takes the full width of the grid item
                }}
              >
                <img
                  loading="lazy"
                  alt=""
                  src={Animoo}
                  style={{
                    maxWidth: "90%",
                    maxHeight: "100%",
                    objectFit: "cover", // Ensure the image fits within the box and maintains its aspect ratio
                  }}
                />
              </Box>
            </Grid>
          </Grid>

          <Box
            sx={{
              display: { xs: "block", md: "block", xl: "block" },
              width: { xs: "100%", md: "50%" },
              marginLeft: "auto",
            }}
          >
            <TokenInfoCard
              title={"4.	Swap $SOL for $MPETZ"}
              content={
                "Swap SOL for $MPETZ. You can use the site buttons to buy the token now."
              }
              style={{
                marginLeft: "auto",
              }}
            />
          </Box>

          {/* <Grid container >
            <Grid item xs={12} md={6} >
              <TokenInfoCard
                title={"Contract"}
                content={
                  "  Lorem ipsum dolor sit amet consectetur. Est aliquam fermentummetus fringilla. Mattis ullamcorper netus aliquam sit id."
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Box>
                {" "}
                <SocialIcons />
              </Box>
            </Grid>
          </Grid> */}
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          sx={{ py: 0, my: 0, display: { xs: "none", md: "block" } }}
        >
          <Box
            sx={{
              py: 0,
              my: 0,
              position: "relative",
            }}
          >
            <img
              loading="lazy"
              alt=""
              src={animatedUfo}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
            <img
              loading="lazy"
              alt=""
              src={moopet}
              style={{
                position: "absolute",
                bottom: 0,
                left: "23%",
                // left: "12.8rem",
                height: "60%",
              }}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              my: { xs: 2, md: 4 },
              mx: 2,
            }}
          >
            <img
              loading="lazy"
              alt=""
              src={milkSplit}
              style={{
                minWidth: "57%",
                minHeight: "50%",
                objectFit: "cover",
                marginTop: "50px"
              }}
            />
          </Box>

          {/* <Box sx={{
 display:{ xs :"none" , md:"flex", xl:"none" },
 justifyContent: "center",
 alignItems: "left",
 textAlign: "center",

}}>



<Box sx={{ display:{ xs :"none" , md:"block", xl:"none" }, width: { xs: "100%", md: "50%" }, paddingTop:"3em"   } }>
            <TokenInfoCard
              title={"4.	Swap $SOL for $MPETZ"}
              content={
                "Swap SOL for $MPETZ. We only have 1% taxes so you don’t need to worry about buying with a specific slippage, although you may need to use slippage during times of market volatility."
              }
              style={{
                marginLeft: "auto",
              }}
            />
          </Box>

          </Box> */}
        </Grid>
      </Grid>
    </>
  );
}
