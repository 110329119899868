import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import CustomButton from "../utils/Button";
import logo from "../assets/logo.png";
import { Icon } from "@mui/material";
import { handleScroll } from "../utils/utils";
import { useLocation, useNavigate } from "react-router-dom";

const settings = ["Profile", "Account", "Dashboard", "Logout"];

const pages = [
  "About",
  "How to buy $MPETZ",
  "Buy $MPETZ",
  "Community",
  // "Tokenomics",
  "Roadmap",
];

function ResponsiveAppBar() {
  const location = useLocation();
  const navigate = useNavigate();
  const openLink = () => {
    window.open(
      "https://pump.fun/ECRNyo34xm5EWWstfcQmX5MomnHjKERoSYDR8Kjwpump",
      "_blank"
    );
  };

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const pagesMenu = [
    "About",
    "How to buy $MPETZ",
    "Buy $MPETZ",
    "Community",
    // "Tokenomics",
    "Roadmap",
    "Connect",
  ];

  const handleOnClick = (page: string) => {
    if (page == "About"|| page == "Community" ) {

      navigate("/about");
  
    }else if(page == "Buy $MPETZ"){
      window.open(
      "https://pump.fun/ECRNyo34xm5EWWstfcQmX5MomnHjKERoSYDR8Kjwpump",
      "_blank"
        );
    }
    
    else if (page == "Roadmap") {

      if(location.pathname=="/"){
        handleScroll("road-map");
      }
      else {
        navigate("/");
        setTimeout(() => handleScroll("road-map"), 1000);
      }
    

    }
    else if (page == "How to buy $MPETZ") {

      if(location.pathname=="/"){
        handleScroll("how-to-buy-content");
      }
      else {
        navigate("/");
        setTimeout(() => handleScroll("how-to-buy-content"), 1000);
      }
    

    }
    
    else if (page == "Tokenomics") {
      navigate("/token-nomics");
    } else if ((page = "")) {
      window.open("https://bridge.pepe.vip", "_blank");
    } else {

      if(location.pathname=="/"){
        handleScroll("how-to-buy");
      }else {
        navigate("/");
        setTimeout(() =>  handleScroll("how-to-buy"), 1000);
      }
    }

    handleCloseNavMenu();
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: "transparent",

        boxShadow: "none",
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ display: { xs: "none", lg: "flex" }, mr: 1 }}>
            <img
              src={logo}
              alt="Custom Icon"
              style={{
                height: "80px",
                width: "80px",
              }}
              onClick={() => navigate("/")}
            />
          </Box>
          <Typography
            variant="h6"
            noWrap
            component="a"
            sx={{
              mr: 2,
              display: { xs: "none", lg: "flex" },
              fontWeight: 500,
              letterSpacing: ".1rem",
              color: "inherit",
              textDecoration: "none",
              fontFamily: "Cherry Bomb !important",
              fontSize: "2em",
            }}
            onClick={() => navigate("/")}
          >
            The MooPetz
          </Typography>

          <Box sx={{ display: { xs: "flex", lg: "none" }, mr: 1 }}>
            <img
              src={logo}
              alt="Custom Icon"
              style={{
                height: "4.113rem",
                width: "4.375rem",
              }}
              onClick={() => navigate("/")}
            />
          </Box>
          <Typography
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { xs: "none", lg: "none" },
              flexGrow: 1,
              fontFamily: "Cherry Bomb !important",
              fontWeight: 500,
              letterSpacing: ".1rem",
              color: "inherit",
              textDecoration: "none",
              fontSize: "1.2em",
            }}
            onClick={() => navigate("/")}
          >
            The MooPetz
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", lg: "flex" },
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {pages.map((page) => (
              <Button
                key={page}
                onClick={() => {
                  handleOnClick(page);
                }}
                sx={{
                  my: 2,
                  color: "white",
                  display: "block",
                  fontFamily: "Jost",
                  fontWeight: 500,
                  fontSize: "14px",
                  marginTop: '22px'
                }}
              >
                {page}
              </Button>
            ))}
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "flex", lg: "none" },
              justifyContent: "flex-end",
            }}
          >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
              sx={{ margin: 0, padding: 0 }}
            >
              <MenuIcon sx={{ fontSize: "1.4em" }} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", lg: "none" },
              }}
            >
              {pagesMenu.map((page) => (
                <MenuItem
                  key={page}
                  onClick={() => {
                    handleOnClick(page);
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "0.8em",
                      fontFamily: "Jost",
                      fontWeight: 500,
                    }}
                    textAlign="center"
                  >
                    {page}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Box
            sx={{ display: { xs: "none", lg: "flex" }, mr: { xs: 0, lg: 1 } }}
          >
            <CustomButton onClick={openLink} text="Buy Tokens" />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
